<template>
  <section class="sectionSideCart">
    <div class="sectionSideCart-copy">
      <div class="sectionSideCart-container">
        <h1>{{ fields.title }}</h1>
        <span v-html="description"></span>
      </div>
    </div>
    <div
      class="sectionSideCart-image"
      v-lazy:background-image="
        updateImageUrl(`${this.fields.image.fields.file.url}?w=800`)
      "
    ></div>
  </section>
</template>

<script>
const marked = require('marked');

export default {
  props: ['fields'],
  computed: {
    description: function() {
      return marked(this.fields.description);
    },
  },
};
</script>
