<template>
  <header class="header" :class="{ 'header--border': border }">
    <div class="header-wrap">
      <Brand></Brand>
      <div class="header-container">
        <ul class="header-contact">
          <li class="header-adress">
            <a
              :href="`https://www.google.com/maps/place/${fields.address}/`"
              target="_blank"
              >{{ fields.address }}</a
            >
          </li>
          <li class="header-telephone">{{ fields.telephone }}</li>
        </ul>
        <SocialNav :fields="fields" small="true" outline="true"></SocialNav>
        <Navigation
          :fields="fields.mainNavigation"
          :adress="fields.address"
          :telephone="fields.telephone"
          v-on:close-nav="closeMenu"
        ></Navigation>
        <div class="header-lang">
          <router-link :to="changeLanguageInPath('nl')">NL</router-link> |
          <router-link :to="changeLanguageInPath('en')">EN</router-link>
        </div>
        <button
          class="header-menubtn"
          ref="showMenu"
          v-if="!isMenuOpen"
          aria-label="Open menu"
          @click="showMenu"
        ></button>
        <button
          class="header-menubtn--close"
          ref="closeMenu"
          v-if="isMenuOpen"
          aria-label="close menu"
          @click="closeMenu"
        ></button>
      </div>
    </div>
  </header>
</template>

<script>
import Brand from '@/components/brand.vue';
import SocialNav from '@/components/socialNav.vue';
import Navigation from '@/components/navigation.vue';
import helpers from '@/lib/helpers';

export default {
  props: {
    fields: {
      type: Object,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  components: {
    Brand,
    SocialNav,
    Navigation,
  },
  methods: {
    changeLanguageInPath: function(lang) {
      return helpers.changeLanguageInPath(this.$route.fullPath, lang);
    },

    showMenu: function() {
      this.isMenuOpen = !this.isMenuOpen;
      document.querySelector('.navigation').style.display = 'block';
      document.querySelector('html').style.overflow = 'hidden';
    },

    closeMenu: function() {
      this.isMenuOpen = !this.isMenuOpen;
      document.querySelector('.navigation').style.display = 'none';
      document.querySelector('html').style.overflow = 'scroll';
    },
  },
};
</script>
