<template>
  <section class="sectionRegister" v-if="fields">
    <div class="sectionRegister-wrap">
      <div class="sectionRegister-highlites">
        <h1>{{ fields.loginTitle }}</h1>
        <p v-html="toHtml(fields.loginDescription)"></p>
        <Btn :link="fields.loginCtaLink" primary openBlank>{{
          fields.loginCta
        }}</Btn>
      </div>

      <div class="sectionRegister-copy">
        <h1>{{ fields.title }}</h1>
        <div v-html="toHtml(fields.description)"></div>
        <ContactForm
          :sendTo="fields.sendTo"
          :fields="fields"
          :show-contact-form="true"
        />
        <!--        <iframe-->
        <!--          class="sectionRegister__frame"-->
        <!--          :src="getIframe()"-->
        <!--          frameborder="0"-->
        <!--          scrolling="no"-->
        <!--        ></iframe>-->
      </div>
    </div>
  </section>
</template>

<script>
import Btn from '@/components/btn.vue';
import locales from '../lib/locales';
import ContactForm from '../components/contactForm';

import { mapState } from 'vuex';
const marked = require('marked');

export default {
  props: ['fields'],
  computed: {
    description: function() {
      return marked(this.fields.description);
    },
    ...mapState({
      currentSite: state => state.currentSite,
      locale: state => state.locale,
    }),
  },
  components: {
    Btn,
    ContactForm,
  },
  methods: {
    toHtml: function(item) {
      return marked(item);
    },
    getIframe: function() {
      if (this.currentSite === 'foodies') {
        if (this.locale === locales.NL) {
          return 'https://scrn.zapfloorhq.com/customers/foodies/leadiframe2/937910783308694122179872379215350834840323802.html';
        } else {
          return 'https://scrn.zapfloorhq.com/customers/foodies/leadiframe-foodies-arena-en/9379107833086941221872379215350834840323802.html';
        }
      } else {
        return 'https://scrn.zapfloorhq.com/customers/foodies/leadiframe3-office-arena/937910783308694122179872379215350834840323802.html';
      }
    },
  },
};
</script>
