<template>
  <div class="brand">
    <router-link :to="'/' + $store.getters.language + '/home'">
      <img src="../assets/img/logo.svg" alt="" /><br />
      <img src="../assets/img/logo-text.svg" class="brand-title" alt="" />
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'sectionBrand',
  props: ['fields'],
  computed: {
    ...mapState({
      currentSite: state => state.currentSite,
    }),
  },
};
</script>
