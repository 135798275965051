<template>
  <router-link
    :to="{ path: '/' + $store.getters.language + '/' + link, params: {} }"
    v-if="isSlug(link)"
    class="btn"
    :class="{
      'btn--primary': primary,
      'btn--green': green,
      'btn--transparent': transparent,
      'btn--red': red,
    }"
    @click="track"
  >
    <slot>button</slot>
  </router-link>

  <a
    v-else
    v-bind:href="link"
    class="btn"
    :class="{
      'btn--primary': primary,
      'btn--green': green,
      'btn--transparent': transparent,
      'btn--red': red,
    }"
    @click="track"
    rel="noopener"
    :target="openBlank ? '_blank' : '_self'"
  >
    <slot>button</slot>
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    trackObject: Object,
    openBlank: Boolean,
  },
  methods: {
    isSlug: function(link) {
      //slug check
      if (link) {
        return /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(link);
      } else {
        return false;
      }
    },
    track: function() {
      if (this.trackObject) {
        // gtag.event(this.trackObject);
      }
    },
  },
};
</script>
