<template>
  <section class="sectionContact">
    <div class="sectionContact-wrap">
      <h1>{{ fields.title }}</h1>

      <div class="sectionContact-form">
        <ContactForm
          :sendTo="fields.sendTo"
          :fields="fields"
          :show-contact-form="true"
        />
      </div>

      <div class="sectionContact-details" v-html="info"></div>
    </div>
  </section>
</template>

<script>
import ContactForm from '../components/contactForm';

const marked = require('marked');

export default {
  props: ['fields'],
  computed: {
    info: function() {
      return marked(this.fields.info);
    },
  },
  components: {
    ContactForm,
  },
};
</script>
