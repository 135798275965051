<template>
  <section class="sectionHost">
    <div class="sectionHost-copy">
      <h1>{{ fields.title }}</h1>
      <!-- {{fields.description}} -->
      <div v-html="toHtml(fields.description)"></div>
    </div>
    <div class="sectionHost-avatar">
      <img
        class="avatar"
        v-lazy="updateImageUrl(`${fields.avatar.fields.file.url}?w=600`)"
        alt=""
      />
    </div>
  </section>
</template>

<script>
const marked = require('marked');

export default {
  props: ['fields'],
  methods: {
    toHtml: function(text) {
      return marked(text);
    },
  },
};
</script>
