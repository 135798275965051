<template>
  <section class="sectionFormulesOverview" v-if="fields">
    <ul
      class="formulesOverview"
      v-bind:class="[moreThanThree() ? 'formulesOverview-moreThanThree' : '']"
      v-if="fields.formules"
    >
      <li
        class="formulesOverview-item"
        v-for="formule in fields.formules"
        v-bind:key="formule.fields.title"
        :id="makeId(formule.fields.title)"
      >
        <div class="formulesOverview-wrapper">
          <div
            v-if="formule.fields.image"
            class="formulesOverview-image"
            v-lazy:background-image="
              updateImageUrl(
                `${formule.fields.image.fields.file.url}?w=400&h=400`,
              )
            "
          ></div>

          <div class="formulesOverview-content">
            <div class="formulesOverview-title">{{ formule.fields.title }}</div>
            <div class="formulesOverview-shortDescription">
              {{ formule.fields.shortDescription }}
            </div>
            <a
              class="btn"
              :href="formule.fields.ctaLink"
              @click="track(formule.fields.title)"
              >{{ formule.fields.cta }}</a
            >
            <div
              class="formulesOverview-copy"
              v-html="toHtml(formule.fields.description)"
            ></div>
            <div class="formulesOverview-price">{{ formule.fields.price }}</div>
            <div
              class="formulesOverview-conditions"
              v-if="formule.fields.conditions"
            >
              {{ formule.fields.conditions }}
            </div>
            <!-- <router-link :to="/order" class="btn">Reserveer</router-link> -->
            <!--<Btn link="contact">{{formule.fields.cta}}</Btn>-->
            <div class="formulesOverview-extra" v-if="formule.fields.extra">
              {{ formule.fields.extra }}
            </div>
          </div>
        </div>
        <!-- <div class="formuleList-image" v-bind:style="{backgroundImage:backgroundImage(formule)}">
        </div>-->
        <!-- <div class="formuleList-description">{{ formule.fields.description }}</div> -->
      </li>
    </ul>
  </section>
</template>

<script>
import Flickity from 'flickity';
require('flickity-imagesloaded');
require('@/assets/flickity.min.css');
import forEach from 'lodash/forEach';

const marked = require('marked');

const equaliseFormulasHeight = function() {
  const items = document.querySelectorAll('.formulesOverview-wrapper');
  if (window.innerWidth > 820 && items) {
    let maxHeight = 0;

    forEach(items, item => {
      maxHeight < item.clientHeight ? (maxHeight = item.clientHeight) : null;
    });

    forEach(items, item => {
      item.style.height = `${maxHeight}px`;
    });
  } else {
    /* remove all adjusted heights for mobile */
    forEach(items, item => {
      item.removeAttribute('style');
    });
  }
};

export default {
  props: ['fields'],

  mounted() {
    if (this.moreThanThree()) {
      new Flickity('.formulesOverview', {
        contain: true,
        groupCells: true,
        cellAlign: 'left',
        watchCSS: true,
        pageDots: false,
        adaptiveHeight: true,
        hash: true,
      });
    }

    equaliseFormulasHeight();

    window.addEventListener('resize', equaliseFormulasHeight);
  },
  destroyed() {
    window.removeEventListener('resize', equaliseFormulasHeight);
  },
  methods: {
    toHtml: function(text) {
      return marked(text);
    },
    makeId: function(id) {
      return id.replace(' ', '').toLowerCase();
    },
    moreThanThree: function() {
      return this.fields.formules.length > 3;
    },
    track: function() {
      // this.$ga.event({
      //   eventCategory: 'Reservatieaanvraag',
      //   eventAction: 'Klik',
      //   eventLabel: 'Reserveer jouw plaats',
      //   eventValue: value
      // });
      // gtag.event('Reservatieaanvraag', 'Klik', 'Reserveer jouw plaats', 2);
    },
  },
};
</script>
