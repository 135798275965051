var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"articleGrid"},[(_vm.showVacatures)?[(!_vm.articles)?[_c('h2',{staticClass:"no-content"},[_vm._v(_vm._s(_vm.fields.noItems))])]:_vm._e(),_vm._l((_vm.articles),function(article,key,index){return [(article.isVacature)?[(index < _vm.maxItems)?_c('router-link',{key:key,staticClass:"articleGrid-item",class:'articleGrid-item' + index,attrs:{"to":'/' + _vm.$store.getters.language + '/article/' + key}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
              _vm.updateImageUrl(
                article.header.fields.file.url + '?w=1200&h=1200',
              )
            ),expression:"\n              updateImageUrl(\n                article.header.fields.file.url + '?w=1200&h=1200',\n              )\n            ",arg:"background-image"}],staticClass:"articleGrid-image"},[_c('div',{staticClass:"articleGrid-hover"},[_c('span',[_vm._v(_vm._s(_vm.fields.readMore))])])]),_c('div',{staticClass:"articleGrid-category",class:_vm.categoryClasses(article.category)},[_vm._v("\n            "+_vm._s(article.category)+"\n          ")]),_c('div',{staticClass:"articleGrid-info"},[_c('div',{staticClass:"articleGrid-title",domProps:{"innerHTML":_vm._s(article.title)}}),_c('div',{staticClass:"articleGrid-summary",domProps:{"innerHTML":_vm._s(_vm.toHtml(_vm.summary(article.bodyPreview)))}})])]):_vm._e()]:_vm._e()]})]:_vm._e(),(!_vm.showVacatures)?[(!_vm.articles)?[_c('h2',{staticClass:"no-content"},[_vm._v(_vm._s(_vm.fields.noItems))])]:_vm._e(),_vm._l((_vm.articles),function(article,key,index){return [(!article.isVacature)?[(index < _vm.maxItems)?_c('router-link',{key:key,staticClass:"articleGrid-item",class:'articleGrid-item' + index,attrs:{"to":'/' + _vm.$store.getters.language + '/article/' + key}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
              _vm.updateImageUrl(
                article.header.fields.file.url + '?w=1200&h=1200',
              )
            ),expression:"\n              updateImageUrl(\n                article.header.fields.file.url + '?w=1200&h=1200',\n              )\n            ",arg:"background-image"}],staticClass:"articleGrid-image"},[_c('div',{staticClass:"articleGrid-hover"},[_c('span',[_vm._v(_vm._s(_vm.fields.readMore))])])]),_c('div',{staticClass:"articleGrid-category",class:_vm.categoryClasses(article.category)},[_vm._v("\n            "+_vm._s(article.category)+"\n          ")]),_c('div',{staticClass:"articleGrid-info"},[_c('div',{staticClass:"articleGrid-title",domProps:{"innerHTML":_vm._s(article.title)}}),_c('div',{staticClass:"articleGrid-summary",domProps:{"innerHTML":_vm._s(_vm.toHtml(_vm.summary(article.bodyPreview)))}})])]):_vm._e()]:_vm._e()]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }