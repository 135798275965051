<template>
  <section class="sectionFeatures" v-if="fields">
    <div class="container">
      <h1>{{ fields.title }}</h1>
      <ul class="featureList" v-if="fields.features">
        <li
          class="featureList__item"
          :class="[feature.fields.icon ? 'featureList__item--center' : '']"
          v-for="feature in fields.features"
          v-bind:key="feature.fields.title"
        >
          <img
            class="featureList__icon"
            v-if="feature.fields.icon"
            v-lazy="
              updateImageUrl(`${feature.fields.icon.fields.file.url}?w=300`)
            "
            alt=""
          />
          <div class="featureList__header">
            <a
              v-if="feature.sys.contentType.sys.id !== 'workshop'"
              :href="feature.fields.ctaLink"
              ><h2
                class="featureList__title"
                v-html="toHtml(feature.fields.title)"
              ></h2>
            </a>
            <a
              v-if="
                feature.sys.contentType.sys.id === 'workshop' &&
                  !feature.fields.isAMember
              "
              :href="
                `/${$store.getters.language}/workshop/${feature.fields.slug}`
              "
              ><h2
                class="featureList__title"
                v-html="toHtml(feature.fields.title)"
              ></h2
            ></a>
            <a
              v-if="
                feature.sys.contentType.sys.id === 'workshop' &&
                  feature.fields.isAMember
              "
              :href="
                `/${$store.getters.language}/member/${feature.fields.slug}`
              "
              ><h2
                class="featureList__title"
                v-html="toHtml(feature.fields.title)"
              ></h2
            ></a>
          </div>
          <div class="featureList__visuals" v-if="feature.fields.visuals">
            <flickity
              ref="flickity"
              :options="flickityOptions"
              v-if="feature.fields.visuals.length > 1"
            >
              <img
                class="carousel-cell"
                v-for="(visual, index) in feature.fields.visuals"
                :key="`${visual.fields.title}-visual-${index}`"
                v-lazy="updateImageUrl(`${visual.fields.file.url}?w=600`)"
                :alt="visual.fields.title"
              />
            </flickity>

            <img
              v-else
              v-for="(visual, index) in feature.fields.visuals"
              :key="`${visual.fields.title}-visual-${index}`"
              v-lazy="updateImageUrl(`${visual.fields.file.url}?w=600`)"
              :alt="visual.fields.title"
            />
          </div>
          <div
            class="featureList__copy"
            ref="featureList__copy"
            v-html="toHtml(feature.fields.description)"
          ></div>
          <div class="featureList__price">
            {{ feature.fields.price }}
          </div>
          <div
            class="featureList__cta"
            v-if="
              feature.fields.ctaLink &&
                feature.sys.contentType.sys.id !== 'workshop'
            "
          >
            <Btn :link="feature.fields.ctaLink">{{ feature.fields.cta }}</Btn>
          </div>
          <div
            class="featureList__cta"
            v-if="
              feature.sys.contentType.sys.id === 'workshop' &&
                !feature.fields.isAMember
            "
          >
            <Btn
              :link="
                `/${$store.getters.language}/workshop/${feature.fields.slug}`
              "
              >{{ fields.moreInfo }}</Btn
            >
          </div>
          <div
            class="featureList__cta"
            v-if="
              feature.sys.contentType.sys.id === 'workshop' &&
                feature.fields.isAMember
            "
          >
            <Btn
              :link="
                `/${$store.getters.language}/member/${feature.fields.slug}`
              "
              >{{ fields.moreInfo }}</Btn
            >
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Flickity from 'vue-flickity';
import Btn from '@/components/btn.vue';
import helpers from '@/lib/helpers';
const marked = require('marked');

const equaliseTextHeight = function(items) {
  if (window.innerWidth > 820) {
    let maxHeight = 0;

    items.forEach(item => {
      maxHeight < item.clientHeight ? (maxHeight = item.clientHeight) : null;
    });

    items.forEach(item => {
      item.style.height = `${maxHeight}px`;
    });
  } else {
    /* remove all adjusted heights for mobile */
    items.forEach(item => {
      item.removeAttribute('style');
    });
  }
};

export default {
  props: ['fields'],
  components: {
    Btn,
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        imagesLoaded: true,
        pageDots: false,
        // any options from Flickity can be used
      },
    };
  },
  mounted() {
    this.$nextTick(function() {
      console.log('section features mounted');
      if (this.$refs.featureList__copy) {
        equaliseTextHeight(this.$refs.featureList__copy);
      }
      window.dispatchEvent(new Event('resize'));
      window.addEventListener('resize', () =>
        equaliseTextHeight(this.$refs.featureList__copy),
      );
    });
  },
  destroyed() {
    window.removeEventListener('resize', () =>
      equaliseTextHeight(this.$refs.featureList__copy),
    );
  },
  methods: {
    summary: function(s) {
      return helpers.summary(s, 300);
    },
    toHtml: function(item) {
      return marked(item);
    },
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>
