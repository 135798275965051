<template>
  <div class="cookie-popup" v-if="!agreedToCookies">
    <div class="cookie-popup__content">
      <div class="cookie-popup__text">{{ cookieText }}</div>
      <div class="cookie-popup__actions">
        <Btn @click.native="closeCookiePopup()" red>OK</Btn>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/btn';

export default {
  name: 'CookiePopup',
  props: {
    cookieText: String,
  },
  components: {
    Btn,
  },
  data() {
    return {
      agreedToCookies: false,
    };
  },
  created() {
    this.agreedToCookies = this.$cookies.get('agreedToCookies');
  },
  methods: {
    closeCookiePopup: function() {
      this.agreedToCookies = true;
      this.$cookies.set('agreedToCookies', true);
    },
  },
};
</script>
