import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/store';
import simpleLayout from './layouts/simpleLayout';
import defaultLayout from './layouts/defaultLayout';
import { ObserveVisibility } from 'vue-observe-visibility';
import * as Sentry from '@sentry/browser';
import VueCookies from 'vue-cookies';
import VueLazyload from 'vue-lazyload';
import Meta from 'vue-meta';
import VueObserveVisibility from 'vue-observe-visibility';

//layouts
Vue.component('simpleLayout', simpleLayout);
Vue.component('defaultLayout', defaultLayout);
Vue.directive('observe-visibility', ObserveVisibility);
//
Vue.use(VueCookies);
Vue.use(VueLazyload);
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueObserveVisibility);

VueCookies.config('30d');

Vue.config.productionTip = false;

// error logging
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://da814e8e389c44c49856f98b0db46316@sentry.io/1407715',
    integrations: [
      new Sentry.Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

Vue.mixin({
  methods: {
    updateImageUrl: url => {
      // update images to webp if it is supported!
      let msie = parseInt(
        (/msie (\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1],
      );

      let is_safari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );

      if (isNaN(msie)) {
        msie = parseInt(
          (/trident\/.*; rv:(\d+)/.exec(navigator.userAgent.toLowerCase()) ||
            [])[1],
        );
      }
      if (isNaN(msie) && !is_safari) {
        // was able or not to get WebP representation
        const newUrl = url + '&fm=webp';
        return newUrl;
      } else {
        return url;
      }
    },
  },
});

// set the correct site (foodies / office)to the store
store.dispatch('setCurrentSite');

let SocialSharing = require('vue-social-sharing');
Vue.use(SocialSharing);

router.beforeEach((to, from, next) => {
  // all language changes come via router
  // set the language of the Store
  store.dispatch('setLocaleByLanguage', to.params.lang);
  next();
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
