import Vue from 'vue';
import Router from 'vue-router';
import sectionedPage from './pages/sectionedPage.vue';
import locales from './lib/locales';
import helpers from './lib/helpers';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/nl',
      redirect: '/' + helpers.getLanguageFromLocale(locales.NL) + '/home',
    },
    {
      path: '/en',
      redirect: '/' + helpers.getLanguageFromLocale(locales.EN) + '/home',
    },
    {
      path: '/:lang/company-events',
      redirect: '/' + helpers.getLanguageFromLocale(locales.NL) + '/workshops',
    },
    {
      path: '/:lang/privacyPolicy',
      name: 'privacyPolicy',
      meta: { layout: 'simpleLayout' },
      component: sectionedPage,
    },
    {
      path: '/:lang/userAgreement',
      name: 'userAgreement',
      meta: { layout: 'simpleLayout' },
      component: sectionedPage,
    },
    {
      path: '/:lang/article/:articleSlug',
      name: 'article',
      component: sectionedPage,
    },
    {
      path: '/:lang',
      name: 'home',
      component: sectionedPage,
    },
    {
      path: '/:lang/:pageSlug',
      name: 'sectionedPage',
      component: sectionedPage,
    },
    {
      path: '/:lang/workshop/:workshopSlug',
      name: 'workshop',
      component: () => import('./pages/workshopPage.vue'),
    },
    {
      path: '/:lang/member/:workshopSlug',
      name: 'workshop',
      component: () => import('./pages/memberPage.vue'),
    },
    {
      path: '/',
      redirect: '/' + helpers.getLanguageFromLocale(locales.NL) + '/home',
    },
    {
      path: '*',
      name: '404',
      component: () => import('./pages/errorPage.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
