<template>
  <div class="shareArticle" v-if="article">
    <div class="shareArticle-copy" v-if="copy">
      {{ copy }}
    </div>

    <!-- {{article.title}} -->
    <!-- <social-sharing :url="'https://foodiesarena.netlify.com'+$route.fullPath" -->
    <social-sharing
      :url="'https://foodiesarena.be/' + $route.fullPath"
      :title="article.title"
      :description="summary(article)"
      :quote="summary(article)"
      :hashtags="article.category"
      twitter-user=""
      inline-template
    >
      <div class="shareArticle-icons">
        <network class="shareArticle-facebook" network="facebook">
          Facebook
        </network>
        <network class="shareArticle-linkedin" network="linkedin">
          Twitter
        </network>
        <!-- more networks available, twitter, https://github.com/nicolasbeauvais/vue-social-sharing-->
      </div>
    </social-sharing>
  </div>
</template>

<script>
import helpers from '@/lib/helpers';

export default {
  props: ['article', 'copy'],
  methods: {
    summary: function(article) {
      //some articles do not have intro
      if (article.intro && article.intro.length) {
        return article.intro;
      }
      return helpers.summary(article.body);
    },
  },
};
</script>
