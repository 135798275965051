<template>
  <section class="sectionTour" v-if="fields">
    <h1>{{ fields.title }}</h1>
    <div class="sectionTour__content">
      <iframe
        width="100%"
        height="640"
        style="width: 100%; height: 640px; border: none; max-width: 100%;"
        frameborder="0"
        allowfullscreen="true"
        allow="vr,gyroscope,accelerometer,fullscreen"
        scrolling="no"
        :src="fields.tourUrl"
      ></iframe>
    </div>
    <ul class="sectionTour__visuals">
      <li v-for="(visual, i) in fields.visuals" :key="i">
        <img
          v-lazy="updateImageUrl(`${visual.fields.file.url}?w=400`)"
          alt=""
        />
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  props: ['fields'],
  name: 'sectionTour',
};
</script>
