<template>
  <div id="app" v-if="initialised">
    <nprogress-container></nprogress-container>
    <main role="main" :class="siteName">
      <!-- <router-view/> -->
      <component :is="layout">
        <router-view :key="$route.fullPath" />
      </component>
    </main>
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import { mapState } from 'vuex';

// import './styles/style.scss';
require('./styles/style.scss');
//IE6 polyfill
require('es6-object-assign').polyfill();

require('nprogress');
const DEFAULT_LAYOUT = 'defaultLayout';

export default {
  created: function() {
    this.$store.dispatch('init');
  },
  computed: {
    layout() {
      return this.$route.meta.layout || DEFAULT_LAYOUT;
    },
    url() {
      return this.$route.fullPath.toLowerCase(); // Get the current route's full path and convert it to lowercase
    },
    siteName() {
      if (this.url.includes('foodies')) return 'foodies';
      else return 'office';
    },
    ...mapState(['initialised']),
  },
  watch: {
    url() {
      this.siteName; // Trigger the computed property recalculation
    },
  },
  components: {
    NprogressContainer,
  },
};
</script>
