<template>
  <section class="sectionFormules" v-if="fields">
    <h1>{{ fields.title }}</h1>
    <div
      class="sectionFormules-subTitle"
      v-html="toHtml(fields.subTitle)"
    ></div>

    <div
      :class="`formuleGrid formuleGrid--${fields.formules.length}`"
      v-if="fields.formules"
    >
      <router-link
        :to="'/' + $store.getters.language + '/formules'"
        class="formuleGrid-item"
        :class="'formuleGrid-item' + key"
        v-for="(value, key) in fields.formules"
        v-bind:key="value.fields.title"
        v-lazy:background-image="
          updateImageUrl(`${value.fields.image.fields.file.url}?w=800&h=800`)
        "
      >
        <div class="formuleGrid-itemCopy">
          {{ value.fields.teaser }}
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
const marked = require('marked');

export default {
  props: ['fields'],
  methods: {
    toHtml: function(text) {
      return marked(text);
    },
  },
};
</script>
