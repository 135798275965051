<template>
  <section class="sectionPopOut">
    <div
      class="sectionPopOut-wrap"
      :class="[fields.reverseContent ? 'sectionPopOut-wrap--reverse' : '']"
    >
      <div class="sectionPopOut-copy">
        <h1>{{ fields.title }}</h1>
        <div class="sectionPopOut-cols">
          <div
            class="sectionPopOut-copyLeft"
            v-html="toHtml(fields.descriptionLeftCol)"
          ></div>
          <div
            class="sectionPopOut-copyRight"
            v-html="toHtml(fields.descriptionRightCol)"
          ></div>
        </div>
      </div>
      <div class="sectionPopOut-highlites">
        <h1>{{ fields.popupTitle }}</h1>
        <div v-html="toHtml(fields.popupDescription)"></div>
        <Btn :link="fields.ctaLink">{{ fields.cta }}</Btn>
      </div>
    </div>
  </section>
</template>

<script>
import Btn from '@/components/btn';
const marked = require('marked');

export default {
  props: ['fields'],
  components: {
    Btn,
  },
  methods: {
    toHtml: function(item) {
      if (item) {
        return marked(item);
      }
    },
  },
};
</script>
