<template>
  <section
    class="sectionHero"
    v-lazy:background-image="
      updateImageUrl(`${this.fields.image.fields.file.url}?w=1400`)
    "
    v-if="fields"
  >
    <div class="sectionHero__content">
      <h1 class="sectionHero-title" :title="`${fields.title}`">
        {{ title }} <b class="sectionHero-typewriter" v-if="verb">{{ verb }}</b>
      </h1>

      <p class="sectionHero-description">{{ fields.description }}</p>

      <Btn :link="fields.ctaLink" primary :trackObject="trackObjectLink">{{
        fields.cta
      }}</Btn>
      <Btn
        :link="fields.ctaLink2"
        green
        primary
        v-if="fields.cta2"
        :trackObject="trackObjectLink2"
        >{{ fields.cta2 }}</Btn
      >
      <Btn
        :link="fields.ctaLink3"
        primary
        v-if="fields.cta3"
        :trackObject="trackObjectLink3"
        >{{ fields.cta3 }}</Btn
      >
    </div>
    <!--    <template v-if="currentSite === 'foodies'">-->
    <!--      <a-->
    <!--        href="/nl/workshops"-->
    <!--        class="sectionHero__pin-button"-->
    <!--        v-if="currentPage.title === 'home' && language === 'nl'"-->
    <!--      >-->
    <!--        <img-->
    <!--          v-lazy="-->
    <!--            updateImageUrl(-->
    <!--              'https://images.ctfassets.net/u316f4f5yfp8/15ANuSkBXxcDBy4qwvi2WW/eb6239edb68e9e645118b5f3441e1d8b/pin-button-nl.png?fm=webp',-->
    <!--            )-->
    <!--          "-->
    <!--          alt="Simona Catering badge"-->
    <!--        />-->
    <!--      </a>-->
    <!--      <a-->
    <!--        href="/en/workshops"-->
    <!--        class="sectionHero__pin-button"-->
    <!--        v-if="currentPage.title === 'home' && language === 'en'"-->
    <!--      >-->
    <!--        <img-->
    <!--          v-lazy="-->
    <!--            updateImageUrl(-->
    <!--              'https://images.ctfassets.net/u316f4f5yfp8/3kl55LAJVWXMiOswvQHjHi/112f551f0d01dbd38fdae6a8c1ef7fa4/pin-button-en.png?fm=webp',-->
    <!--            )-->
    <!--          "-->
    <!--          alt="Simona Catering badge"-->
    <!--        />-->
    <!--      </a>-->
    <!--    </template>-->
    <!--    <template v-if="currentSite === 'office'">-->
    <!--      <a-->
    <!--        href="/nl/meeting-rooms"-->
    <!--        class="sectionHero__pin-button"-->
    <!--        v-if="currentPage.title === 'home' && language === 'nl'"-->
    <!--      >-->
    <!--        <img-->
    <!--          v-lazy="-->
    <!--            updateImageUrl(-->
    <!--              'https://images.ctfassets.net/u316f4f5yfp8/15ANuSkBXxcDBy4qwvi2WW/eb6239edb68e9e645118b5f3441e1d8b/pin-button-nl.png?fm=webp',-->
    <!--            )-->
    <!--          "-->
    <!--          alt="Simona Catering badge"-->
    <!--        />-->
    <!--      </a>-->
    <!--      <a-->
    <!--        href="/en/meeting-rooms"-->
    <!--        class="sectionHero__pin-button"-->
    <!--        v-if="currentPage.title === 'home' && language === 'en'"-->
    <!--      >-->
    <!--        <img-->
    <!--          v-lazy="-->
    <!--            updateImageUrl(-->
    <!--              'https://images.ctfassets.net/u316f4f5yfp8/3kl55LAJVWXMiOswvQHjHi/112f551f0d01dbd38fdae6a8c1ef7fa4/pin-button-en.png?fm=webp',-->
    <!--            )-->
    <!--          "-->
    <!--          alt="Simona Catering badge"-->
    <!--        />-->
    <!--      </a>-->
    <!--    </template>-->
  </section>
</template>

<script>
import Btn from '@/components/btn';
import { mapGetters } from 'vuex';
let timer;
let interval;

export default {
  props: ['fields'],
  data: function() {
    return {
      verb: '',
      trackObjectLink: {
        eventCategory: 'Contactopname',
        eventAction: 'Klik',
        eventLabel: 'Contact',
        eventValue: 'naar contact page',
      },
      trackObjectLink2: {
        eventCategory: 'Reservatieaanvraag',
        eventAction: 'Klik',
        eventLabel: 'Reserveer jouw plaats',
        eventValue: '',
      },
      trackObjectLink3: {
        eventCategory: 'navigation',
        eventAction: 'Klik',
        eventLabel: 'Button',
        eventValue: 'button 3',
      },
    };
  },
  computed: {
    title: function() {
      /*
      data looks like this: "A new place to [work,grow,cook,learn]"
      */
      return this.fields.title.split('[')[0].trim();
    },
    verbs: function() {
      /*
      data looks like this: "A new place to [work,grow,cook,learn]"
      */
      let s = this.fields.title;
      return s.substring(s.lastIndexOf('[') + 1, s.lastIndexOf(']')).split(',');
    },
    currentSite: function() {
      return process.env.VUE_APP_SITE;
    },
    ...mapGetters(['currentPage', 'language']),
  },
  destroyed: function() {
    clearTimeout(timer);
    clearInterval(interval);
  },
  mounted: function() {
    // let texts = ["werken", "groeien", "koken", "leren"];
    let textsCounter = 1;
    let j = 0;
    let txt = this.verbs[0];
    let speed = 100; /* The speed/duration of the effect in milliseconds */
    const self = this;

    function typeWriter() {
      if (j < txt.length) {
        self.verb += txt.charAt(j);
        j++;
        if (timer) clearTimeout(timer);
        timer = setTimeout(typeWriter, speed);
      }
    }

    typeWriter();

    function resetTypeWriter() {
      self.verb = '';
      j = 0;
      txt = self.verbs[textsCounter];
      textsCounter++;
      typeWriter();
    }

    interval = setInterval(() => {
      if (textsCounter === this.verbs.length) {
        textsCounter = 0;
      }

      resetTypeWriter();
    }, 3000);
  },
  components: {
    Btn,
  },
};
</script>
