import Vue from 'vue';
import Vuex from 'vuex';
import { createClient } from 'contentful';
import locales from '../lib/locales';
import helpers from '../lib/helpers';
import get from 'lodash/get';

/*
contentful configuration
*/
const ctf = createClient({
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCES_TOKEN,
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
});

const types = {
  SET_INIT: 'setInit',
  SET_WEBSITE: 'setWebsite',
  ADD_PAGE: 'addPage',
  ADD_ARTICLES: 'addArticles',
  ADD_ARTICLE: 'addArticle',
  SET_RELATED_ARTICLES: 'setRelatedArticles',
  SET_CURRENT_PAGE: 'setCurrentPage',
  SET_CURRENT_ARTICLE: 'setCurrentArticle',
  SET_CURRENT_WORKSHOP: 'setCurrentWorkshop',
  SET_CURRENT_SITE: 'setCurrentSite',
  SET_CURRENT_POPUP: 'setCurrentPopup',
  SET_LOCALE: 'setLocale',
  RESET: 'reset',
};

Vue.use(Vuex);

const ARTICLE_BATCH = 4; // amount of articles to load in one batch
const RELATED_ARTICLES = 3; // amount of related articles

const state = {
  pages: {},
  articles: {},
  latestArticleSlugs: [],
  latestVacaturesSlugs: [],
  totalArticles: 0,
  allArticlesLoaded: false, // true if all articles from cms have been loaded
  relatedArticleSlugs: [], //articles that are related to the currentArticle
  website: null,
  initialised: false,
  currentPageSlug: null,
  currentArticleSlug: null,
  currentWorkshop: null,
  currentPopup: null,
  locale: locales.NL,
  currentSite: 'foodies',
};

const mutations = {
  addPage(state, payload) {
    const page = payload.result.items[0];
    if (page && page.fields) {
      Vue.set(state.pages, payload.pageSlug, page.fields);
    } else {
      //no page found for this slug
      //page not found?
      console.log('no page found for', payload.pageSlug);
    }
  },
  addArticles(state, payload) {
    state.totalArticles = payload.result.total;

    payload.result.items.forEach(function(article) {
      //add articles to the article state
      if (article.fields.isVacature) {
        state.latestVacaturesSlugs.push(article.fields.slug);
      } else {
        if (state.locale === locales.EN) {
          if (article.fields.publishInEnglish) {
            state.latestArticleSlugs.push(article.fields.slug);
          }
        } else {
          state.latestArticleSlugs.push(article.fields.slug);
        }
      }

      Vue.set(state.articles, article.fields.slug, article.fields);
    });

    //check if all have loaded
    state.allArticlesLoaded =
      state.latestArticleSlugs.length + state.latestVacaturesSlugs.length >=
      state.totalArticles;
  },
  setRelatedArticles(state, payload) {
    state.relatedArticleSlugs = [];
    payload.result.items.forEach(function(article) {
      //add/update articles to the article state
      Vue.set(state.articles, article.fields.slug, article.fields);
      state.relatedArticleSlugs.push(article.fields.slug);
    });
  },
  addArticle(state, payload) {
    const article = payload.result.items[0];
    if (article && article.fields) {
      Vue.set(state.articles, article.fields.slug, article.fields);
    } else {
      // no article for this slug
    }
  },
  setCurrentPage(state, pageSlug) {
    if (state.pages[pageSlug]) {
      state.currentPageSlug = pageSlug;
    } else {
      //can not find page with this slug
      console.log('currentPage', pageSlug, 'does not exist');
    }
  },
  setCurrentArticle(state, payload) {
    state.currentArticleSlug = payload;
  },
  setCurrentWorkshop(state, payload) {
    state.currentWorkshop = payload;
  },
  setCurrentPopup(state, payload) {
    state.currentPopup = payload;
  },
  setWebsite(state, payload) {
    // get first website object "[0]" as default
    state.website = payload.items[0].fields;
  },
  setInit(state) {
    state.initialised = true;
  },
  setLocale(state, locale) {
    state.locale = locale;
  },
  setCurrentSite(state, payload) {
    state.currentSite = payload;
  },
  reset(state) {
    //reset the whole store chabang
    state.pages = {};
    state.articles = {};
    state.latestArticleSlugs = [];
    state.totalArticles = 0;
    state.allArticlesLoaded = false;
    state.relatedArticleSlugs = [];
    state.website = null;
    state.initialised = false;
    state.currentPageSlug = null;
    state.currentArticleSlug = null;
    state.currentWorkshop = null;
    state.currentPopup = null;
    state.locale = locales.NL;
    state.currentSite = 'foodies';
  },
};

const actions = {
  async setPage({ commit, state }, payload) {
    const pageSlug = payload.slug;
    const forceReload = payload.forceReload;
    /*
    do not load page again, if already in state
    */
    if (!state.pages[pageSlug] || forceReload) {
      const result = await ctf.getEntries({
        // fetch the page by slug
        content_type: 'page',
        'fields.slug[in]': pageSlug,
        include: 5,
        locale: state.locale,
      });

      commit(types.ADD_PAGE, { pageSlug, result });
    }
    // set it as currentPage
    commit(types.SET_CURRENT_PAGE, pageSlug);
  },

  async setNextArticles({ commit, state }, numberOfItems = 4) {
    // if(!state.latestArticleSlugs){
    const skip =
      state.latestArticleSlugs.length >= ARTICLE_BATCH - 1
        ? state.latestArticleSlugs.length + 1
        : 0;
    const result = await ctf.getEntries({
      // load next 5 articles
      content_type: 'article',
      limit: numberOfItems,
      skip,
      // order: 'sys.createdAt',
      order: '-sys.createdAt',
      include: 3,
      locale: state.locale,
    });

    commit(types.ADD_ARTICLES, { result });
    // }
  },

  async setRelatedArticles({ commit }) {
    const category = get(state.articles[state.currentArticleSlug], 'category');
    const result = await ctf.getEntries({
      // load category related articles
      content_type: 'article',
      'fields.category': category,
      'fields.slug[ne]': state.currentArticleSlug, //do not include current article
      limit: RELATED_ARTICLES,
      order: '-sys.createdAt',
      include: 3,
      locale: state.locale,
    });
    // commit
    commit(types.SET_RELATED_ARTICLES, { result });
  },
  /*
  getting navigations, social url's etc,
  */
  async setWebsite({ commit }) {
    commit(
      types.SET_WEBSITE,
      await ctf.getEntries({
        content_type: 'website',
        include: 1,
        locale: state.locale,
      }),
    );
  },

  async setArticle({ dispatch, commit, state }, articleSlug) {
    /*
    do not load article again, if already in state
    */
    if (!state.articles[articleSlug]) {
      /*
      load that article
      */
      const result = await ctf.getEntries({
        // fetch article by slug
        content_type: 'article',
        'fields.slug[in]': articleSlug,
        include: 5,
        order: '-sys.createdAt',
        locale: state.locale,
      });
      commit(types.ADD_ARTICLE, { articleSlug, result });
    } else {
      // console.log("aricle already in state: ", articleSlug)
    }
    // set it as currentPage
    commit(types.SET_CURRENT_ARTICLE, articleSlug);
    //set the related articles for this article
    dispatch('setRelatedArticles');
  },

  async setWorkshop({ commit, state }, workshopSlug) {
    // console.log('get workshop', workshopSlug);
    const result = await ctf.getEntries({
      // fetch article by slug
      content_type: 'workshop',
      'fields.slug[in]': workshopSlug.toLowerCase(),
      include: 1,
      locale: state.locale,
    });

    // console.log('current workshop returned', result.items[0]);
    // set it as currentPage
    commit(types.SET_CURRENT_WORKSHOP, result.items[0]);
  },

  async setPopup({ commit, state }) {
    const result = await ctf.getEntries({
      // fetch article by slug
      content_type: 'popup',
      include: 1,
      locale: state.locale,
    });

    if (result.items) {
      commit(types.SET_CURRENT_POPUP, result.items[0]);
    } else {
      commit(types.SET_CURRENT_POPUP, null);
    }
  },

  /*
  initializing the app
  */
  async init({ dispatch, commit }) {
    // await dispatch('getNavigation')
    await dispatch('setWebsite');
    await dispatch('setNextArticles');
    await dispatch('setPopup');
    commit(types.SET_INIT);
  },
  setLocaleByLanguage({ dispatch, commit, state }, lang) {
    //default language to 'NL'
    lang = lang || 'NL';

    //check if locale is a valid locale, else switch to default
    const locale = locales[lang.toUpperCase()]
      ? locales[lang.toUpperCase()]
      : locales.NL;
    //check if the languages has changed
    if (state.locale != locale) {
      //reset the store, empty it from other language content
      commit(types.RESET);
      //set the new locale
      commit(types.SET_LOCALE, locale);
      commit(types.SET_CURRENT_SITE, process.env.VUE_APP_SITE);
      // re initialise with new locale
      dispatch('init');
    }
  },
  setCurrentSite({ commit }) {
    // console.log('current site', process.env.VUE_APP_SITE);
    commit(types.SET_CURRENT_SITE, process.env.VUE_APP_SITE);
  },
};

const getters = {
  currentPage: state => state.pages[state.currentPageSlug],
  currentArticle: state => state.articles[state.currentArticleSlug],
  currentWorkshop: state => {
    return state.currentWorkshop;
  },
  currentPopup: state => {
    return state.currentPopup;
  },
  latestArticleBatches: state => {
    //this will return articlesslugs in batches
    const ammount = Math.ceil(state.latestArticleSlugs.length / ARTICLE_BATCH);
    let a = [];

    for (let i = 0; i < ammount; i++) {
      a.push(
        state.latestArticleSlugs.slice(
          i * ARTICLE_BATCH,
          i * ARTICLE_BATCH + ARTICLE_BATCH,
        ),
      );
    }
    return a;
  },
  latestVacaturesBatches: state => {
    //this will return articlesslugs in batches
    const ammount = Math.ceil(
      state.latestVacaturesSlugs.length / ARTICLE_BATCH,
    );
    let a = [];
    for (let i = 0; i < ammount; i++) {
      a.push(
        state.latestVacaturesSlugs.slice(
          i * ARTICLE_BATCH,
          i * ARTICLE_BATCH + ARTICLE_BATCH,
        ),
      );
    }

    return a;
  },
  language: state => helpers.getLanguageFromLocale(state.locale),
};

export default new Vuex.Store({ state, getters, actions, mutations });
