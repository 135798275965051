<!--
  section will render markdown.
-->
<template>
  <section class="sectionCopy" v-if="fields">
    <h1>{{ fields.title }}</h1>
    <div class="sectionCopy-wrap" v-if="fields.copy" v-html="copy"></div>
  </section>
</template>

<script>
const marked = require('marked');

export default {
  props: ['fields'],
  computed: {
    copy: function() {
      return marked(this.fields.copy);
    },
  },
};
</script>
