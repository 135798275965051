<template>
  <section class="sectionOverview" v-if="fields">
    <h1>{{ fields.title }}</h1>
    <div
      class="sectionOverview__subtitle"
      v-if="fields.intro || fields.introButtonLink"
    >
      <span v-html="toHtml(fields.intro)" v-if="fields.intro"></span>
      <a
        :href="fields.introButtonLink"
        v-if="fields.introButtonLink"
        class="btn btn--green btn--primary"
        target="_blank"
      >
        {{ fields.introButton }}
      </a>
    </div>
    <div
      class="sectionOverview__items"
      v-if="activeEvents(fields.items).length > 0"
    >
      <event
        v-for="(item, i) in activeEvents(fields.items)"
        :price="fields.price"
        :moreInfo="fields.moreInfo"
        :fields="item.fields"
        :type="fields.type"
        :isTimeSlot="fields.eventsAreTimeslots"
        :detailButton="fields.detailButton"
        :key="i"
      />
    </div>
    <div
      class="sectionOverview__items"
      v-if="activeEvents(fields.items).length === 0"
    >
      <img src="../assets/img/no-events-orange.svg" alt="" width="200" />

      <h2>{{ fields.noItems }}</h2>

      <hr style="margin-top: 50px" />
    </div>
    <div
      v-if="oldEvents(fields.items).length > 0 && !fields.eventsAreTimeslots"
    >
      <h2
        class="sectionOverview__subtitle sectionOverview__subtitle--extra-space"
      >
        {{ fields.pastItems }}
      </h2>
      <div class="sectionOverview__items">
        <event
          v-for="(item, i) in oldEvents(fields.items)"
          :price="fields.price"
          :moreInfo="fields.moreInfo"
          :type="fields.type"
          :fields="item.fields"
          :key="i"
          :isOld="true"
          :detailButton="fields.detailButton"
        />
        <button
          class="btn btn--green"
          @click="loadMoreEvents"
          :aria-label="fields.moreItems"
          v-if="maxOldItemsPerClick * oldEventsPaging < totalOldItems"
        >
          {{ fields.moreItems }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Event from '@/components/event';
import forEach from 'lodash/forEach';
import dayjs from 'dayjs';

const marked = require('marked');

export default {
  components: { Event },
  props: ['fields'],
  data: function() {
    return {
      oldEventsPaging: 1,
      maxOldItemsPerClick: 3,
      totalOldItems: 0,
    };
  },
  methods: {
    toHtml: function(item) {
      return marked(item);
    },
    oldEvents: function(items) {
      const oldItems = [];
      forEach(items, item => {
        if (dayjs().isAfter(item.fields.until)) {
          oldItems.push(item);
        }
      });

      this.totalOldItems = oldItems.length;

      oldItems.sort(function compare(a, b) {
        const dateA = new Date(a.fields.until);
        const dateB = new Date(b.fields.until);
        return dateA - dateB;
      });

      oldItems.reverse();

      return oldItems.slice(0, this.maxOldItemsPerClick * this.oldEventsPaging);
    },

    loadMoreEvents: function() {
      this.oldEventsPaging++;
    },

    activeEvents: function(items) {
      const activeItems = [];
      forEach(items, item => {
        if (dayjs().isBefore(item.fields.until)) {
          activeItems.push(item);
        }
      });

      activeItems.sort(function compare(a, b) {
        const dateA = new Date(a.fields.until);
        const dateB = new Date(b.fields.until);
        return dateA - dateB;
      });

      return activeItems;
    },
  },
};
</script>

<style scoped></style>
