<template>
  <!-- =================== VACATURE =================    -->

  <div class="articleGrid">
    <template v-if="showVacatures">
      <template v-if="!articles">
        <h2 class="no-content">{{ fields.noItems }}</h2>
      </template>
      <template v-for="(article, key, index) in articles">
        <template v-if="article.isVacature">
          <router-link
            :to="'/' + $store.getters.language + '/article/' + key"
            class="articleGrid-item"
            :class="'articleGrid-item' + index"
            v-if="index < maxItems"
            :key="key"
          >
            <div
              class="articleGrid-image"
              v-lazy:background-image="
                updateImageUrl(
                  article.header.fields.file.url + '?w=1200&h=1200',
                )
              "
            >
              <div class="articleGrid-hover">
                <span>{{ fields.readMore }}</span>
              </div>
            </div>

            <div
              class="articleGrid-category"
              :class="categoryClasses(article.category)"
            >
              {{ article.category }}
            </div>
            <div class="articleGrid-info">
              <div class="articleGrid-title" v-html="article.title"></div>
              <div
                class="articleGrid-summary"
                v-html="toHtml(summary(article.bodyPreview))"
              ></div>
            </div>
          </router-link>
        </template>
      </template>
    </template>
    <!-- =================== VACATURE - END =================    -->

    <!-- =================== BLOG =================    -->
    <template v-if="!showVacatures">
      <template v-if="!articles">
        <h2 class="no-content">{{ fields.noItems }}</h2>
      </template>
      <template v-for="(article, key, index) in articles">
        <template v-if="!article.isVacature">
          <router-link
            :to="'/' + $store.getters.language + '/article/' + key"
            class="articleGrid-item"
            :class="'articleGrid-item' + index"
            v-if="index < maxItems"
            :key="key"
          >
            <div
              class="articleGrid-image"
              v-lazy:background-image="
                updateImageUrl(
                  article.header.fields.file.url + '?w=1200&h=1200',
                )
              "
            >
              <div class="articleGrid-hover">
                <span>{{ fields.readMore }}</span>
              </div>
            </div>
            <div
              class="articleGrid-category"
              :class="categoryClasses(article.category)"
            >
              {{ article.category }}
            </div>
            <div class="articleGrid-info">
              <div class="articleGrid-title" v-html="article.title"></div>
              <div
                class="articleGrid-summary"
                v-html="toHtml(summary(article.bodyPreview))"
              ></div>
            </div>
          </router-link>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import categories from '../lib/categories.js';
import helpers from '@/lib/helpers';
import { mapState } from 'vuex';

const marked = require('marked');

export default {
  props: {
    articleSlugs: {
      type: Array,
    },
    mirror: {
      type: Boolean,
      default: false,
    },
    showVacatures: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 100,
    },
    readMore: String,
    fields: Object,
  },
  computed: {
    ...mapState(['articles']),
  },

  methods: {
    summary: function(s) {
      return helpers.summary(s);
    },
    toHtml: function(item) {
      return marked(item);
    },
    categoryClasses(cat) {
      return 'category-' + categories.byName(cat).id;
    },
  },
};
</script>
