<template>
  <section v-if="currentPage">
    <div
      class="partners partners--white"
      v-if="
        currentPage.slug === 'workshops' ||
          currentPage.slug === 'formules' ||
          currentPage.slug === 'teambuildings'
      "
    >
      <h1 v-if="language === 'NL'">Onze klanten</h1>
      <h1 v-if="language !== 'NL'">Our clients</h1>
      <ul>
        <li v-for="(client, i) in fields.clients" :key="i">
          <img
            v-lazy="updateImageUrl(`${client.fields.file.url}?w=200&h=200`)"
            :alt="client.fields.title"
          />
        </li>
      </ul>
    </div>
    <div class="partners" v-else>
      <h1 v-if="language === 'NL'">Onze partners</h1>
      <h1 v-if="language !== 'NL'">Our partners</h1>
      <ul>
        <li v-for="(partner, i) in fields.partners" :key="i">
          <img
            v-lazy="updateImageUrl(`${partner.fields.file.url}?w=200&h=200`)"
            :alt="partner.fields.title"
          />
        </li>
      </ul>
    </div>
    <footer class="footer">
      <SocialNav :fields="fields"></SocialNav>

      <div class="footer-legalNav" v-if="fields.footerNavigation">
        <router-link
          v-for="item in fields.footerNavigation"
          :to="'/' + language + '/' + item.fields.slug"
          :key="item.fields.slug"
          target="_blank"
        >
          {{ item.fields.title }}
        </router-link>
      </div>
    </footer>
    <CookiePopup :cookieText="fields.cookiesPopup" />
  </section>
</template>

<script>
import SocialNav from '@/components/socialNav';
import { mapGetters } from 'vuex';
import CookiePopup from '@/components/subcomponents/CookiePopup';

export default {
  props: ['fields'],
  components: {
    SocialNav,
    CookiePopup,
  },
  computed: {
    ...mapGetters(['currentPage', 'language']),
  },
};
</script>
