<template>
  <div class="event" itemscope itemtype="https://schema.org/Event">
    <div class="event__date" v-if="!isTimeSlot">
      <span class="event__date__day">{{ day }}</span>
      <span class="event__date__month">{{ month }}</span>
      <span class="event__date__year">{{ year }}</span>

      <span itemprop="startDate" style="display: none">{{ fields.date }}</span>
      <span itemprop="endDate" style="display: none">{{ fields.until }}</span>
      <span itemprop="performer" style="display: none">{{ fields.until }}</span>

      <template v-if="!isSameDay">
        <span class="arrow">
          <img src="../assets/img/arrow.svg" alt="" />
        </span>
        <span class="event__date__day">{{ dayUntil }}</span>
        <span class="event__date__month">{{ monthUntil }}</span>
        <span class="event__date__year">{{ yearUntil }}</span>
      </template>
    </div>

    <div class="event__time" v-if="isTimeSlot" itemprop="startDate">
      <span class="event__time__from">{{ timeFrom }}</span>
      <span class="arrow">
        <img src="../assets/img/arrow.svg" alt="" />
      </span>
      <span class="event__time__until">{{ timeUntil }}</span>
    </div>

    <div class="event__info">
      <h2 itemprop="name" itemtype="https://schema.org/name">
        {{ fields.title }}
      </h2>
      <div
        v-html="toHtml(fields.intro)"
        itemprop="about"
        v-if="fields.intro"
      ></div>
      <div
        v-html="toHtml(fields.intro)"
        style="display: none"
        v-if="fields.intro"
      ></div>
      <p itemprop="offers" itemscope itemtype="https://schema.org/offer">
        <span
          v-if="priceInfo && !isTimeSlot"
          itemprop="price"
          :content="fields.price"
          ><span class="light">{{ price }}</span> {{ priceInfo }}</span
        >
        <a :href="fields.link" itemprop="url" style="display: none"> </a>
        <span style="display: none" itemprop="priceCurrency">
          EUR
        </span>
        <span itemprop="validFrom" style="display: none">{{
          fields.date
        }}</span>

        <span v-if="fields.type"
          ><span class="light">{{ type }}</span> {{ fields.type }}</span
        >
      </p>
      <div v-if="!isOld && detailButton && fields.link">
        <a
          :href="fields.link"
          class="btn btn--green"
          itemprop="url"
          target="_blank"
          >{{ detailButton }}</a
        >
      </div>

      <a :href="fields.photoGallery" v-if="fields.photoGallery"
        >{{ fields.photoGallery }}
      </a>
      <p
        @click="toggleDetail"
        :class="toggleClasses"
        v-if="fields.detail"
        role="button"
      >
        {{ moreInfo }} <img src="../assets/img/chevron.svg" alt="" />
      </p>

      <div
        class="event__detail"
        v-if="fields.detail"
        v-show="detailIsOpen"
        itemprop="description"
        v-html="toHtml(fields.detail)"
      ></div>
    </div>

    <span itemprop="performer" style="display: none">
      {{ currentSite }}
    </span>

    <div
      class="location"
      style="display: none;"
      itemprop="location"
      itemscope
      itemtype="https://schema.org/Place"
    >
      <span v-if="currentSite === 'foodies'" itemprop="name"
        >Foodies Arena</span
      >
      <span v-if="currentSite === 'office'" itemprop="name">Office Arena</span>
      <div
        itemprop="address"
        itemscope
        itemtype="https://schema.org/PostalAddress"
        v-if="currentSite === 'foodies'"
      >
        <span itemprop="name">Foodies Arena</span><br />
        <span itemprop="streetAddress">Paul Fredericqstraat 30</span><br />
        <span itemprop="addressLocality">Gent</span>
        <span itemprop="addressRegion">Oost vlaanderen</span>
        <span itemprop="postalCode">9000</span><br />
        <span itemprop="addressCountry">België</span>
      </div>

      <div
        itemprop="address"
        itemscope
        itemtype="https://schema.org/PostalAddress"
        v-if="currentSite === 'office'"
      >
        <span itemprop="name">Office Arena</span><br />
        <span itemprop="streetAddress">Paul Fredericqstraat 30</span><br />
        <span itemprop="addressLocality">Gent</span>
        <span itemprop="addressRegion">Oost vlaanderen</span>
        <span itemprop="postalCode">9000</span><br />
        <span itemprop="addressCountry">België</span>
      </div>
    </div>

    <div class="event__visual" v-if="fields.visual">
      <img
        v-lazy="
          updateImageUrl(`${this.fields.visual.fields.file.url}?w=600&h=600`)
        "
        itemprop="image"
      />
    </div>
  </div>
</template>

<script>
const marked = require('marked');
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import locales from '@/lib/locales';
import { mapState } from 'vuex';

export default {
  props: {
    fields: Object,
    price: String,
    moreInfo: String,
    isOld: Boolean,
    isTimeSlot: {
      type: Boolean,
      default: false,
    },
    type: String,
    detailButton: String,
  },
  data: function() {
    return {
      detailIsOpen: false,
    };
  },
  name: 'event',
  computed: {
    day: function() {
      return dayjs(this.fields.date).date();
    },
    month: function() {
      if (this.locale === locales.NL) {
        return dayjs(this.fields.date)
          .locale('nl')
          .format('MMM');
      }
      return dayjs(this.fields.date).format('MMM');
    },
    year: function() {
      return dayjs(this.fields.date).year();
    },

    dayUntil: function() {
      return dayjs(this.fields.until).date();
    },
    monthUntil: function() {
      if (this.locale === locales.NL) {
        return dayjs(this.fields.until)
          .locale('nl')
          .format('MMM');
      }
      return dayjs(this.fields.until).format('MMM');
    },
    yearUntil: function() {
      return dayjs(this.fields.until).year();
    },

    priceInfo: function() {
      if (this.fields.price > 0) {
        return `€ ${this.fields.price}`;
      } else {
        if (this.locale === locales.NL) {
          return 'gratis';
        } else {
          return 'free';
        }
      }
    },
    timeFrom: function() {
      return dayjs(this.fields.date).format('HH:mm');
    },
    timeUntil: function() {
      return dayjs(this.fields.until).format('HH:mm');
    },
    toggleClasses: function() {
      return [
        'event__toggle',
        {
          'event__toggle--open': this.detailIsOpen,
          'event__toggle--margin': this.fields.link,
        },
      ];
    },
    isSameDay: function() {
      return dayjs(this.fields.date).isSame(this.fields.until, 'day');
    },
    ...mapState({
      locale: state => state.locale,
      currentSite: state => state.currentSite,
    }),
  },
  methods: {
    toHtml: function(item) {
      return marked(item);
    },
    toggleDetail: function() {
      return (this.detailIsOpen = !this.detailIsOpen);
    },
  },
};
</script>
