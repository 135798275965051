<!--
This page is being constructed with sections only
vue component sections resemble in the Contentful cms
-->
<template>
  <div>
    <PopUp />
    <div v-if="currentPage">
      <Section
        v-for="section in currentPage.sections"
        :key="`${section.sys.contentType.sys.id}-${section.sys.createdAt}`"
        :type="section.sys.contentType.sys.id"
        :fields="section.fields"
        >{{ section }}</Section
      >
    </div>
    <div
      v-else
      v-observe-visibility="{
        callback: visibilityChanged,
        throttle: 100,
      }"
    >
      <section class="sectionCentered" v-if="showError">
        <h1>
          Page not found!
        </h1>
        <button
          class="btn btn--primary"
          style="margin: 20px auto;"
          @click="gotoHome"
          aria-label="Go to homepage"
        >
          naar de homepagina
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import Section from '@/components/section';
import PopUp from '@/components/popUp';
import { mapGetters, mapState } from 'vuex';

export default {
  created: function() {
    this.setContent();
  },
  watch: {
    $route() {
      this.setContent();
    },
  },
  data() {
    return {
      showError: false,
    };
  },
  mounted() {},
  computed: {
    pageSlug: function() {
      // some tricky stuff with slugs and stuff
      let pageSlug = this.$route.params.pageSlug || this.$route.name;
      return pageSlug;
    },
    isArticle: function() {
      return this.$route.params.articleSlug ? true : false;
    },
    ...mapGetters(['currentPage', 'currentArticle']),
    ...mapState({
      website: state => state.website,
      currentSite: state => state.currentSite,
    }),
  },
  methods: {
    setContent: function() {
      this.$store.dispatch('setPage', { slug: this.pageSlug });
    },

    gotoHome() {
      this.$store.dispatch('setPage', { slug: 'home' }, true);
    },

    visibilityChanged(isVisible) {
      if (isVisible) {
        this.showError = true;
      }
    },
  },
  components: {
    Section,
    PopUp,
  },
  metaInfo() {
    //check data before using its properties, and when data will be loaded metaInfo will update object as well:
    const currentPage = this.currentPage;
    const currentSite = this.currentSite;

    if (currentPage && currentPage.title !== 'article') {
      return {
        title: currentPage ? currentPage.title : '',
        titleTemplate: currentSite + ' Arena - %s',
        meta: [
          { charset: 'utf-8' },
          {
            name: 'og:title',
            content: currentPage ? currentPage.title : '',
          },
          {
            name: 'og:description',
            content: currentPage ? currentPage.socialText : '',
          },
          {
            name: 'description',
            content: currentPage ? currentPage.socialText : '',
          },
          {
            name: 'og:image',
            property: 'og:image',
            content:
              currentPage && currentPage.socialImage
                ? currentPage.socialImage.fields.file.url
                : './track.jpg',
          },
        ],
        htmlAttrs: {
          lang: 'en',
          amp: true,
        },
      };
    }
  },
};
</script>
