<template>
  <div class="relatedArticleList" v-if="articleSlugs">
    <router-link
      :to="'/' + $store.getters.language + '/article/' + slug"
      class="relatedArticleList-item"
      v-for="slug in articleSlugs"
      :key="slug"
    >
      <div
        class="relatedArticleList-image"
        v-lazy:background-image="
          updateImageUrl(
            `${articles[slug].header.fields.file.url}?w=2300&h=1150`,
          )
        "
      ></div>
      <div
        class="relatedArticleList-category"
        :class="categoryClasses(articles[slug].category)"
      >
        {{ articles[slug].category }}
      </div>
      <div class="relatedArticleList-info">
        <div
          class="relatedArticleList-title"
          v-html="articles[slug].title"
        ></div>
      </div>
    </router-link>
  </div>
</template>

<script>
import categories from '../lib/categories.js';
import { mapState } from 'vuex';

export default {
  props: {
    articleSlugs: {
      type: Array,
    },
  },
  computed: {
    ...mapState(['articles']),
  },
  methods: {
    categoryClasses(cat) {
      return 'category-' + categories.byName(cat).id;
    },
  },
};
</script>
