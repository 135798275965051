<template>
  <div class="contactForm">
    <div v-once id="form"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['fields', 'showContactForm', 'sendTo'],
  mounted: function() {
    this.$nextTick(function() {
      let formID = '46354d24-1e60-45e2-bf15-310d872b426e';
      let redirectURL = `/${this.language}/contact-complete`;
      if (process.env.VUE_APP_SITE === 'office') {
        if (this.language === 'nl') {
          formID = '46354d24-1e60-45e2-bf15-310d872b426e';
        } else {
          formID = 'fe11a28e-17b0-440a-a480-488dc3413c47';
        }
      } else {
        if (this.language === 'nl') {
          formID = 'b79f3c38-1aaa-41f6-aad3-ca1b4ee6d341';
        } else {
          formID = 'a3282441-2f64-4c10-9384-cfaa9c60eee2';
        }
      }
      console.log('create form');
      // eslint-disable-next-line
      hbspt.forms.create({
        portalId: '7371330',
        formId: formID,
        target: `#form`,
        redirectUrl: redirectURL,
      });
      this.$forceUpdate();
    });
  },
  computed: {
    ...mapGetters(['language']),
  },
};
</script>

<style></style>
