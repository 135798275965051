var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fields)?_c('section',{staticClass:"sectionFeatures"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v(_vm._s(_vm.fields.title))]),(_vm.fields.features)?_c('ul',{staticClass:"featureList"},_vm._l((_vm.fields.features),function(feature){return _c('li',{key:feature.fields.title,staticClass:"featureList__item",class:[feature.fields.icon ? 'featureList__item--center' : '']},[(feature.fields.icon)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            _vm.updateImageUrl(((feature.fields.icon.fields.file.url) + "?w=300"))
          ),expression:"\n            updateImageUrl(`${feature.fields.icon.fields.file.url}?w=300`)\n          "}],staticClass:"featureList__icon",attrs:{"alt":""}}):_vm._e(),_c('div',{staticClass:"featureList__header"},[(feature.sys.contentType.sys.id !== 'workshop')?_c('a',{attrs:{"href":feature.fields.ctaLink}},[_c('h2',{staticClass:"featureList__title",domProps:{"innerHTML":_vm._s(_vm.toHtml(feature.fields.title))}})]):_vm._e(),(
              feature.sys.contentType.sys.id === 'workshop' &&
                !feature.fields.isAMember
            )?_c('a',{attrs:{"href":("/" + (_vm.$store.getters.language) + "/workshop/" + (feature.fields.slug))}},[_c('h2',{staticClass:"featureList__title",domProps:{"innerHTML":_vm._s(_vm.toHtml(feature.fields.title))}})]):_vm._e(),(
              feature.sys.contentType.sys.id === 'workshop' &&
                feature.fields.isAMember
            )?_c('a',{attrs:{"href":("/" + (_vm.$store.getters.language) + "/member/" + (feature.fields.slug))}},[_c('h2',{staticClass:"featureList__title",domProps:{"innerHTML":_vm._s(_vm.toHtml(feature.fields.title))}})]):_vm._e()]),(feature.fields.visuals)?_c('div',{staticClass:"featureList__visuals"},[(feature.fields.visuals.length > 1)?_c('flickity',{ref:"flickity",refInFor:true,attrs:{"options":_vm.flickityOptions}},_vm._l((feature.fields.visuals),function(visual,index){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.updateImageUrl(((visual.fields.file.url) + "?w=600"))),expression:"updateImageUrl(`${visual.fields.file.url}?w=600`)"}],key:((visual.fields.title) + "-visual-" + index),staticClass:"carousel-cell",attrs:{"alt":visual.fields.title}})})):_vm._l((feature.fields.visuals),function(visual,index){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.updateImageUrl(((visual.fields.file.url) + "?w=600"))),expression:"updateImageUrl(`${visual.fields.file.url}?w=600`)"}],key:((visual.fields.title) + "-visual-" + index),attrs:{"alt":visual.fields.title}})})],2):_vm._e(),_c('div',{ref:"featureList__copy",refInFor:true,staticClass:"featureList__copy",domProps:{"innerHTML":_vm._s(_vm.toHtml(feature.fields.description))}}),_c('div',{staticClass:"featureList__price"},[_vm._v("\n          "+_vm._s(feature.fields.price)+"\n        ")]),(
            feature.fields.ctaLink &&
              feature.sys.contentType.sys.id !== 'workshop'
          )?_c('div',{staticClass:"featureList__cta"},[_c('Btn',{attrs:{"link":feature.fields.ctaLink}},[_vm._v(_vm._s(feature.fields.cta))])],1):_vm._e(),(
            feature.sys.contentType.sys.id === 'workshop' &&
              !feature.fields.isAMember
          )?_c('div',{staticClass:"featureList__cta"},[_c('Btn',{attrs:{"link":("/" + (_vm.$store.getters.language) + "/workshop/" + (feature.fields.slug))}},[_vm._v(_vm._s(_vm.fields.moreInfo))])],1):_vm._e(),(
            feature.sys.contentType.sys.id === 'workshop' &&
              feature.fields.isAMember
          )?_c('div',{staticClass:"featureList__cta"},[_c('Btn',{attrs:{"link":("/" + (_vm.$store.getters.language) + "/member/" + (feature.fields.slug))}},[_vm._v(_vm._s(_vm.fields.moreInfo))])],1):_vm._e()])})):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }