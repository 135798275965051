<template>
  <div>
    <Header :fields="website" border></Header>

    <slot />

    <Footer :fields="website"></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState(['website']),
  },
};
</script>
