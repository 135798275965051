<template>
  <nav role="navigation" class="navigation">
    <router-link
      v-for="item in fields"
      :to="'/' + $store.getters.language + '/' + item.fields.slug"
      :key="item.fields.slug"
      class="nav-item"
      :class="{ 'navigation-btn': mustBeButton(item) }"
      @click.native="closeNav"
    >
      {{ item.fields.title }}
    </router-link>
    <ul class="header-contact">
      <li class="header-adress">{{ adress }}</li>
      <li class="header-telephone">{{ telephone }}</li>
    </ul>
    <SocialNav :fields="fields" small="true" outline="true"></SocialNav>
  </nav>
</template>

<script>
import SocialNav from '@/components/socialNav.vue';

export default {
  props: ['fields', 'adress', 'telephone'],
  components: {
    SocialNav,
  },
  methods: {
    mustBeButton: function(item) {
      return (
        item.fields.slug.toLowerCase() == 'contact' ||
        item.fields.slug.toLowerCase() == 'member-login'
      );
    },
    closeNav: function() {
      this.$emit('close-nav');
    },
  },
};
</script>
