<template>
  <section class="sectionArticle" v-if="currentArticle">
    <header
      class="sectionArticle-header"
      :class="getColorClass(currentArticle.category)"
    >
      <div class="sectionArticle-headerWrap">
        <div class="sectionArticle-title" v-html="currentArticle.title"></div>
        <div class="sectionArticle-intro" v-if="currentArticle.intro">
          {{ currentArticle.intro }}
        </div>
        <div
          class="sectionArticle-headerImg"
          v-lazy:background-image="
            updateImageUrl(
              `${this.currentArticle.header.fields.file.url}?w=2300&h=1150`,
            )
          "
        ></div>
      </div>
    </header>

    <div class="sectionArticle-wrap">
      <div class="sectionArticle-author">
        <template
          class="sectionArticle-authorWrap"
          v-if="!currentArticle.isVacature"
        >
          <img
            class="sectionArticle-authorAvatar"
            v-lazy="
              updateImageUrl(
                `${currentArticle.author.fields.avatar.fields.file.url}?w=200`,
              )
            "
            alt=""
          />
          <span class="sectionArticle-authorWritten">
            {{ fields.writtenBy }}
          </span>
          <span class="sectionArticle-authorName">
            {{ currentArticle.author.fields.name }}
          </span>
        </template>
        <template class="sectionArticle-authorWrap" v-else>
          <h1>{{ fields.vacatureTitel }}</h1>
          <a
            v-if="currentArticle.vacatureLink"
            :href="currentArticle.vacatureLink"
            class="btn btn--primary"
            >{{ fields.vacatureButton }}</a
          >
        </template>
      </div>

      <div
        class="sectionArticle-body"
        v-html="toHtml(currentArticle.body)"
      ></div>

      <div class="sectionArticle-share">
        <ShareArticle
          v-if="!currentArticle.isVacature"
          :article="currentArticle"
          :copy="fields.share"
        ></ShareArticle>
        <ShareArticle
          v-else
          :article="currentArticle"
          :copy="fields.shareVacature"
        ></ShareArticle>
      </div>
    </div>

    <template v-if="!currentArticle.isVacature">
      <div
        class="sectionArticle-relatedArticles"
        v-if="relatedArticleSlugs.length"
      >
        <h2>{{ fields.related }}</h2>
        <RelatedArticleList
          :articleSlugs="relatedArticleSlugs"
        ></RelatedArticleList>
      </div>
    </template>
  </section>
</template>

<script>
import categories from '../lib/categories.js';
import { mapGetters, mapState } from 'vuex';
import RelatedArticleList from '@/components/relatedArticleList';
import ShareArticle from '@/components/shareArticle';

const marked = require('marked');

export default {
  props: ['fields'],
  created: function() {
    this.$store.dispatch('setArticle', this.$route.params.articleSlug);
  },
  computed: {
    ...mapGetters(['currentArticle']),
    ...mapState(['relatedArticleSlugs', 'articles', 'currentSite']),
  },
  methods: {
    toHtml: function(item) {
      return marked(item);
    },
    getColorClass: function(cat) {
      return 'category-' + categories.byName(cat).id;
    },
  },
  components: {
    RelatedArticleList,
    ShareArticle,
  },
  metaInfo() {
    //check data before using its properties, and when data will be loaded metaInfo will update object as well:
    const currentArticle = this.currentArticle;
    const currentSite = this.currentSite;

    return {
      title: currentArticle ? currentArticle.title : '',
      titleTemplate: currentSite + ' Arena - %s',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'og:title',
          content: currentArticle ? currentArticle.title : '',
        },
        {
          name: 'og:description',
          content: currentArticle ? currentArticle.title : '',
        },
        {
          name: 'description',
          content: currentArticle ? currentArticle.intro : '',
        },
        {
          name: 'og:image',
          property: 'og:image',
          content:
            currentArticle && currentArticle.header
              ? currentArticle.header.fields.file.url
              : './track.jpg',
        },
      ],
      htmlAttrs: {
        lang: 'en',
        amp: true,
      },
    };
  },
};
</script>
