var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSlug(_vm.link))?_c('router-link',{staticClass:"btn",class:{
    'btn--primary': _vm.primary,
    'btn--green': _vm.green,
    'btn--transparent': _vm.transparent,
    'btn--red': _vm.red,
  },attrs:{"to":{ path: '/' + _vm.$store.getters.language + '/' + _vm.link, params: {} }},on:{"click":_vm.track}},[_vm._t("default",[_vm._v("button")])],2):_c('a',{staticClass:"btn",class:{
    'btn--primary': _vm.primary,
    'btn--green': _vm.green,
    'btn--transparent': _vm.transparent,
    'btn--red': _vm.red,
  },attrs:{"href":_vm.link,"rel":"noopener","target":_vm.openBlank ? '_blank' : '_self'},on:{"click":_vm.track}},[_vm._t("default",[_vm._v("button")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }