<template>
  <section class="sectionCentered" v-if="fields">
    <div>
      <h1>{{ fields.title }}</h1>
      <div
        class="sectionCentered__description"
        v-if="fields.description"
        v-html="description"
      ></div>
      <Btn :link="fields.ctaLink" primary v-if="fields.ctaLink"
        >{{ fields.cta }}
      </Btn>
      <img
        class="sectionCentered__visual-button"
        v-lazy="
          updateImageUrl(fields.visualButton.fields.file.url + '?fm=webp&w=400')
        "
        alt=""
        v-if="fields.visualButton"
      />
    </div>
  </section>
</template>

<script>
import Btn from '@/components/btn.vue';

const marked = require('marked');

export default {
  props: ['fields'],
  computed: {
    description: function() {
      return marked(this.fields.description);
    },
  },
  components: {
    Btn,
  },
};
</script>
