<template>
  <section v-if="fields" class="sectionIframe">
    <div :class="containerClasses">
      <iframe
        :src="fields.iframe"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
        ref="myiframe"
        id="myiframe"
        :style="iframeStyle"
        @load="resizeIframe"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  props: ['fields'],
  computed: {
    iframeStyle() {
      return this.fields.fixedHeightInPixels !== undefined
        ? { height: this.fields.fixedHeightInPixels + 'px' }
        : {};
    },
    containerClasses() {
      return {
        'sectionIframe__resp-container':
          this.fields.fixedHeightInPixels === undefined,
      };
    },
  },
  methods: {
    resizeIframe() {
      let newheight = 100;
      if (document.getElementById) {
        newheight = document.getElementById('myiframe').contentWindow.document
          .body.scrollHeight;
      }
      document.getElementById('myiframe').height = newheight + 'px';
    },
  },
};
</script>
