<template>
  <section class="sectionBlog" v-if="fields">
    <div class="sectionBlog-wrap" v-if="!fields.showVacatures">
      <ArticleGrid
        :articleSlugs="latestArticleBatches()"
        :showVacatures="fields.showVacatures"
        :fields="fields"
      ></ArticleGrid>
    </div>

    <div class="sectionBlog-wrap" v-if="fields.showVacatures">
      <ArticleGrid
        :articleSlugs="batch"
        v-for="(batch, key) in latestVacaturesBatches()"
        :showVacatures="fields.showVacatures"
        :key="key + 1"
        :mirror="(key + 1) % 2 == 0"
        :fields="fields"
        :readMore="fields.readMore"
      ></ArticleGrid>
    </div>

    <Btn @click.native="placeNextArticles()" v-if="!allArticlesLoaded">{{
      fields.more
    }}</Btn>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ArticleGrid from '@/components/articleGrid';
import Btn from '@/components/btn';

export default {
  props: ['fields'],
  mounted: function() {
    // load more articles if only 1 batch also check with the vacatures
    if (
      this.latestArticleBatches().length +
        this.latestVacaturesBatches().length <=
      1
    ) {
      this.placeNextArticles();
    }
  },
  computed: {
    ...mapState([
      'allArticlesLoaded',
      'latestArticleSlugs',
      'latestVacaturesSlugs',
    ]),
  },
  methods: {
    placeNextArticles: function() {
      this.$store.dispatch('setNextArticles', 3);
    },
    ...mapGetters(['latestArticleBatches', 'latestVacaturesBatches']),
  },
  components: {
    ArticleGrid,
    Btn,
  },
};
</script>
