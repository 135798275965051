let categories = [
  { id: 'food', name: 'voeding' },
  { id: 'food', name: 'food' },
  { id: 'health', name: 'gezondheid' },
  { id: 'health', name: 'health' },
  { id: 'knowledge', name: 'kennis' },
  { id: 'knowledge', name: 'knowledge' },
  { id: 'knowledge', name: 'entrepreneurship' },
  { id: 'knowledge', name: 'ondernemerschap' },
  { id: 'knowledge', name: 'entrepreneurship' },
  { id: 'work', name: 'werken' },
  { id: 'work', name: 'work' },
  { id: 'news', name: 'nieuws en weetjes' },
  { id: 'news', name: 'news and facts' },
  { id: 'freelance', name: 'freelance werken' },
  { id: 'freelance', name: 'freelance work' },
  { id: 'trends', name: 'trends' },
  { id: 'news', name: 'nieuws en weetjes' },
  { id: 'news', name: 'news and facts' },
  { id: 'vacature', name: 'Vacature' },
  { id: 'vacature', name: 'Job' },
];

export default {
  byName: function(name) {
    return categories.find(function(item) {
      return item.name.toLowerCase() == name.toLowerCase();
    });
  },
};
