import locales from './locales';

export default {
  summary: function(markdown, maxChars = 380) {
    //very crappy way to remove markdown
    return (
      markdown.replace(new RegExp('_', 'g'), '').slice(0, maxChars) + '...'
    );
  },
  changeLanguageInPath(path, lang) {
    lang = lang.toLowerCase();
    //remove any language path prefix
    Object.keys(locales).forEach(function(k) {
      if (path.startsWith('/' + k.toLowerCase())) {
        path = path.slice(k.length + 1);
      }
    });
    return '/' + lang + path;
  },
  getLanguageFromLocale: function(locale) {
    return locale.split('-')[0];
  },
  isValidEmail: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
};
