<template>
  <section
    class="sectionBlogPreview"
    v-if="fields && !checkForArticles(articles)"
  >
    <h1>{{ fields.title }}</h1>
    <div class="sectionBlogPreview-subTitle">{{ fields.subTitle }}</div>
    <ArticleGrid
      :max-items="4"
      :fields="fields"
      :readMore="fields.readMore"
    ></ArticleGrid>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';

import ArticleGrid from '@/components/articleGrid';

export default {
  props: ['fields'],
  computed: {
    ...mapState(['articles', 'latestArticleSlugs']),
  },
  methods: {
    checkForArticles: function(articles) {
      return isEmpty(articles);
    },
  },
  mounted() {
    console.log(this.articles);
  },
  components: {
    ArticleGrid,
  },
};
</script>
