<template>
  <section v-if="currentPopup && modalOpened" class="popup">
    <div class="popup__dialog">
      <a href="#" class="popup__close" @click="closePopup()"
        ><img src="../assets/img/times-circle.svg" alt=""
      /></a>
      <div class="popup__content">
        <a
          v-if="currentPopup.fields.ctaLink && currentPopup.fields.visual"
          :href="currentPopup.fields.ctaLink"
        >
          <img
            class="popup__visual"
            :src="
              updateImageUrl(
                `${currentPopup.fields.visual.fields.file.url}?w=500`,
              )
            "
            alt=""
          />
        </a>
        <img
          v-if="!currentPopup.fields.ctaLink && currentPopup.fields.visual"
          class="popup__visual"
          :src="
            updateImageUrl(
              `${currentPopup.fields.visual.fields.file.url}?w=500`,
            )
          "
          alt=""
        />
        <div
          class="popup__text"
          v-if="currentPopup.fields.title || currentPopup.fields.text"
        >
          <h1 v-if="currentPopup.fields.title">
            {{ currentPopup.fields.title }}
          </h1>
          <div
            v-if="currentPopup.fields.text"
            v-html="toHtml(currentPopup.fields.text)"
          ></div>
          <Btn
            v-if="currentPopup.fields.ctaLink"
            :link="currentPopup.fields.ctaLink"
            >{{ currentPopup.fields.cta }}
          </Btn>
        </div>
      </div>
    </div>
    <div class="popup__bg" @click="closePopup()"></div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Btn from '@/components/btn.vue';
import dayjs from 'dayjs';

const marked = require('marked');
const body = document.querySelector('body');

export default {
  components: {
    Btn,
  },
  mounted() {},
  created() {
    body.style.overflow = 'scroll';
    if (this.currentPopup) {
      // only show the popup when the event is before the end date and after the start date
      if (
        dayjs().isAfter(this.currentPopup.fields.startDate) &&
        dayjs().isBefore(this.currentPopup.fields.endDate)
      ) {
        if (this.currentPopup.fields.shouldPopupEveryVisit) {
          if (sessionStorage.getItem('hidePopup') !== 'true') {
            sessionStorage.setItem('hidePopup', 'true');
            this.modalOpened = true;
            body.style.overflow = 'hidden';
          } else {
            body.style.overflow = 'scroll';
          }
        } else {
          if (
            this.$cookies.get('hidePopup') &&
            this.$cookies.get('hidePopup').id === this.currentPopup.fields.id
          ) {
            body.style.overflow = 'scroll';
          } else {
            this.modalOpened = true;
            body.style.overflow = 'hidden';
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentPopup']),
  },
  data() {
    return {
      modalOpened: false,
    };
  },
  methods: {
    toHtml: function(text) {
      return marked(text);
    },
    closePopup: function() {
      this.$cookies.set('hidePopup', {
        id: this.currentPopup.fields.id,
        hide: true,
      });
      body.style.overflow = 'auto';

      this.modalOpened = false;
    },
  },
};
</script>
