var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentArticle)?_c('section',{staticClass:"sectionArticle"},[_c('header',{staticClass:"sectionArticle-header",class:_vm.getColorClass(_vm.currentArticle.category)},[_c('div',{staticClass:"sectionArticle-headerWrap"},[_c('div',{staticClass:"sectionArticle-title",domProps:{"innerHTML":_vm._s(_vm.currentArticle.title)}}),(_vm.currentArticle.intro)?_c('div',{staticClass:"sectionArticle-intro"},[_vm._v("\n        "+_vm._s(_vm.currentArticle.intro)+"\n      ")]):_vm._e(),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
          _vm.updateImageUrl(
            ((this.currentArticle.header.fields.file.url) + "?w=2300&h=1150"),
          )
        ),expression:"\n          updateImageUrl(\n            `${this.currentArticle.header.fields.file.url}?w=2300&h=1150`,\n          )\n        ",arg:"background-image"}],staticClass:"sectionArticle-headerImg"})])]),_c('div',{staticClass:"sectionArticle-wrap"},[_c('div',{staticClass:"sectionArticle-author"},[(!_vm.currentArticle.isVacature)?[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            _vm.updateImageUrl(
              ((_vm.currentArticle.author.fields.avatar.fields.file.url) + "?w=200"),
            )
          ),expression:"\n            updateImageUrl(\n              `${currentArticle.author.fields.avatar.fields.file.url}?w=200`,\n            )\n          "}],staticClass:"sectionArticle-authorAvatar",attrs:{"alt":""}}),_c('span',{staticClass:"sectionArticle-authorWritten"},[_vm._v("\n          "+_vm._s(_vm.fields.writtenBy)+"\n        ")]),_c('span',{staticClass:"sectionArticle-authorName"},[_vm._v("\n          "+_vm._s(_vm.currentArticle.author.fields.name)+"\n        ")])]:[_c('h1',[_vm._v(_vm._s(_vm.fields.vacatureTitel))]),(_vm.currentArticle.vacatureLink)?_c('a',{staticClass:"btn btn--primary",attrs:{"href":_vm.currentArticle.vacatureLink}},[_vm._v(_vm._s(_vm.fields.vacatureButton))]):_vm._e()]],2),_c('div',{staticClass:"sectionArticle-body",domProps:{"innerHTML":_vm._s(_vm.toHtml(_vm.currentArticle.body))}}),_c('div',{staticClass:"sectionArticle-share"},[(!_vm.currentArticle.isVacature)?_c('ShareArticle',{attrs:{"article":_vm.currentArticle,"copy":_vm.fields.share}}):_c('ShareArticle',{attrs:{"article":_vm.currentArticle,"copy":_vm.fields.shareVacature}})],1)]),(!_vm.currentArticle.isVacature)?[(_vm.relatedArticleSlugs.length)?_c('div',{staticClass:"sectionArticle-relatedArticles"},[_c('h2',[_vm._v(_vm._s(_vm.fields.related))]),_c('RelatedArticleList',{attrs:{"articleSlugs":_vm.relatedArticleSlugs}})],1):_vm._e()]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }