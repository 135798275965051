<template>
  <component :is="section" :fields="fields" :key="sectionKey"></component>
  <!-- </div> -->
</template>

<script>
import sectionHero from '@/components/sectionHero';
import sectionSideCart from '@/components/sectionSideCart';
import sectionContact from '@/components/sectionContact';
import sectionCentered from '@/components/sectionCentered';
import sectionMap from '@/components/sectionMap';
import sectionPopOut from '@/components/sectionPopOut';
import sectionFormules from '@/components/sectionFormules';
import sectionFormulesOverview from '@/components/sectionFormulesOverview';
import sectionCopy from '@/components/sectionCopy';
import sectionExternalContent from '@/components/sectionExternalContent';
import sectionHost from '@/components/sectionHost';
import sectionArticle from '@/components/sectionArticle';
import sectionBlogPreview from '@/components/sectionBlogPreview';
import sectionBlog from '@/components/sectionBlog';
import sectionRegister from '@/components/sectionRegister';
import sectionOverview from '@/components/sectionOverview';
import sectionFeatures from '@/components/sectionFeatures';
import sectionTour from '@/components/sectionTour';
import sectionIframe from '@/components/sectionIframe';

export default {
  props: ['type', 'fields'],
  components: {
    sectionHero,
    sectionSideCart,
    sectionContact,
    sectionCentered,
    sectionMap,
    sectionPopOut,
    sectionFormules,
    sectionFormulesOverview,
    sectionExternalContent,
    sectionCopy,
    sectionHost,
    sectionArticle,
    sectionBlogPreview,
    sectionBlog,
    sectionRegister,
    sectionOverview,
    sectionFeatures,
    sectionTour,
    sectionIframe,
  },
  computed: {
    section: function() {
      return this.type;
    },
    sectionKey: function() {
      return `${this.type}-${Math.random() * 10}`;
    },
  },
};
</script>

<style></style>
