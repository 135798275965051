<template>
  <div
    class="socialNav"
    :class="{ 'socialNav--small': small, 'socialNav--outline': outline }"
  >
    <a
      :href="fields.socialFacebook"
      target="_blank"
      class="socialNav-facebook"
      rel="external"
      >Facebook</a
    >
    <a
      :href="fields.socialInstagram"
      target="_blank"
      class="socialNav-instagram"
      rel="external"
      >Instagram</a
    >
    <a
      :href="fields.socialLinkedin"
      target="_blank"
      class="socialNav-linkedin"
      rel="external"
      >LinkedIn</a
    >
    <a
      :href="fields.socialYoutube"
      target="_blank"
      class="socialNav-youtube"
      rel="external"
      >Youtube</a
    >
  </div>
</template>

<script>
export default {
  props: ['fields', 'small', 'outline'],
};
</script>
