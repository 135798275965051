<template>
  <section
    class="sectionMap"
    v-if="fields"
    v-observe-visibility="visibilityChanged"
  >
    <!-- {{ fields }} -->
    <div id="googleMap"></div>
    <div class="sectionMap-overlay">
      <div class="sectionMap__button-container">
        <btn :link="mapsUrl" green>{{ fields.button }}</btn>
      </div>
    </div>
    <div class="sectionInfo">
      <div class="sectionInfo__item">
        <h2>
          {{ fields.content1Title }}
        </h2>
        <div v-html="toHtml(fields.content1)"></div>
      </div>
      <div class="sectionInfo__item">
        <h2>
          {{ fields.content2Title }}
        </h2>
        <div v-html="toHtml(fields.content2)"></div>
      </div>
      <div class="sectionInfo__item">
        <h2>
          {{ fields.content3Title }}
        </h2>
        <div v-html="toHtml(fields.content3)"></div>
      </div>
    </div>
  </section>
</template>

<script>
import btn from '@/components/btn';

const marked = require('marked');
let mapsIsLoaded = false;

export default {
  props: ['fields'],
  components: {
    btn,
  },
  computed: {
    mapsUrl: function() {
      return process.env.VUE_APP_MAPS_URL;
    },
  },
  methods: {
    toHtml: function(text) {
      return marked(text);
    },
    visibilityChanged(isVisible) {
      if (isVisible && !mapsIsLoaded) {
        mapsIsLoaded = true;

        var mapsapi = require('google-maps-api')(
          'AIzaSyAbs84fuj3lLTDIvg6A9FRjZQOH4ivIszE',
        );
        mapsapi().then(function(maps) {
          // Basic options for a simple Google Map
          // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
          var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 16,
            // centering manually because dynamic refuses to work
            center: new maps.LatLng(51.041267, 3.713241299999936),
            //for some reason shit below won't work. Baffles me!
            // center: new maps.LatLng(this.fields.location.lat, this.fields.location.lon),
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            draggable: false,
            scrollwheel: false,
            panControl: false,
            styles: [
              {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
              },
              {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry.fill',
                stylers: [{ color: '#ffffff' }, { lightness: 17 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [
                  { color: '#ffffff' },
                  { lightness: 29 },
                  { weight: 0.2 },
                ],
              },
              {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }, { lightness: 18 }],
              },
              {
                featureType: 'road.local',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }, { lightness: 16 }],
              },
              {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{ color: '#dedede' }, { lightness: 21 }],
              },
              {
                elementType: 'labels.text.stroke',
                stylers: [
                  { visibility: 'on' },
                  { color: '#ffffff' },
                  { lightness: 16 },
                ],
              },
              {
                elementType: 'labels.text.fill',
                stylers: [
                  { saturation: 36 },
                  { color: '#333333' },
                  { lightness: 40 },
                ],
              },
              { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
              {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
              },
              {
                featureType: 'administrative',
                elementType: 'geometry.fill',
                stylers: [{ color: '#fefefe' }, { lightness: 20 }],
              },
              {
                featureType: 'administrative',
                elementType: 'geometry.stroke',
                stylers: [
                  { color: '#fefefe' },
                  { lightness: 17 },
                  { weight: 1.2 },
                ],
              },
            ],
          };

          // Get the HTML DOM element that will contain your map
          // We are using a div with id='map' seen below in the <body>
          var mapElement = document.getElementById('googleMap');

          // Create the Google Map using our element and options defined above
          var map = new maps.Map(mapElement, mapOptions);

          // Let's also add a marker while we're at it
          var marker = new maps.Marker({
            position: new maps.LatLng(51.041415, 3.713209),
            map: map,
            title: 'Snazzy!',
          });

          marker;
        });
      }
    },
  },
};
</script>
