<!--
  section will render iframe url.
-->
<template>
  <section class="sectionExternalContent" v-if="fields">
    <h1>{{ fields.title }}</h1>
    <iframe
      :src="fields.url"
      :style="{ minWidth: fields.width }"
      :height="fields.height"
      class="sectionExternalContent-iframe"
    ></iframe>
  </section>
</template>

<script>
// const ifr = require('iframe-resizer');
// console.log('ifr', ifr.iframeResizer)
// ifr.iframeResizer({log:true}, '#myIframe')
export default {
  props: ['fields'],
};
</script>
