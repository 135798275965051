<!--
This layout shows legal pages
 -->
<template>
  <div class="simpleLayout">
    <header>
      <Brand></Brand>
    </header>

    <slot />
  </div>
</template>

<script>
import Brand from '@/components/brand';

export default {
  components: {
    Brand,
  },
};
</script>
